import {
	ButtonContainer,
	MessageDiv,
	StyledError,
	StyledGap,
} from '../../../styles/formularz';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { StyledArticle, StyledButton } from '../../../styles';
import {
	StyledFilesTable,
	StyledForm,
	StyledInput,
	StyledLabel,
	StyledSelect,
} from '../../../styles/przetarg';

import Dropzone from 'react-dropzone';
import { Layout } from '../../../components/Layout';
import Loadable from '@loadable/component';
import { StyledH2 } from '../../../styles/common';
import { StyledTableContainer } from '../../../styles/lista';
import axios from '../../../config/axios';
import { navigate } from 'gatsby';
import { useAuth } from '../../../hooks/useAuth';
import { SEO } from '../../../components/SEO';

export const Head = () => (
	<SEO 
	title='Przetarg - edycja'
	description='Edycja przetargu.'
	/>);

const isBrowser = () => typeof window !== 'undefined';

const Editor = Loadable(() => import('../../../components/Editor'));

const EdytujPage = () => {
	const auth = isBrowser()
		? useAuth()
		: { user: '', role: '', verifyUser: () => {}, signOut: () => {} };

	const [status, setStatus] = useState('');
	const [id, setId] = useQueryParam('id', StringParam);
	const [error, setError] = useState('');
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [info, setInfo] = useState('');
	const [files, setFiles] = useState([]);

	useEffect(() => {
		auth.verifyUser();
		const token = localStorage.getItem('token');
		if (!token) {
			navigate('/konto/logowanie/');
		}
	}, []);

	useEffect(() => {
		getTender({ onlyFiles: false });
		document.title=`Przetarg - edycja ${id.replace(/_/g, '/')}`
	}, []);

	const getTender = ({ onlyFiles }: { onlyFiles: boolean }) => {
		try {
			(async () => {
				const response = await axios.get('/get_tender.php', {
					params: { id },
				});
				if (response?.status === 200) {
					const data = response.data;
					setFiles(data.files);
					if (!onlyFiles) {
						setStatus(data.status);
						setTitle(data.title);
						setDescription(data.description);
						setInfo(data.info);
					}
				}
			})();
		} catch (e) {}
	};

	const asyncForEatch = async (array: any, callback: any) => {
		for (let index = 0; index < array.length; index++) {
			await callback(array[index], index, array);
		}
	};

	const uploadFiles = (files: File[]) => {
		if (!id) return;

		asyncForEatch(files, async (file: File) => {
			const formData = new FormData();
			formData.append('id', id);
			formData.append('file', file);
			await axios({
				method: 'post',
				url: '/upload.php',
				data: formData,
				headers: { 'Content-Type': 'multipart/form-data' },
			}).then((response) => {
				if (response?.status === 204) {
					getTender({ onlyFiles: true });
				}
			});
		});
	};

	const editTender = async (e: any) => {
		e.preventDefault();
		if (!id) {
			return;
		}

		try {
			const response = await axios.put('/set_tender.php', {
				id: id.trim(),
				status,
				title: title.trim(),
				description: description.trim(),
				info: info.trim(),
			});
			if (response?.status === 204) {
				navigate('/konto/przetargi/lista');
			}
		} catch (e) {
			// console.log(e.response?.status);
			if (e.response?.status === 400) {
				setError(`Nieprawidłowe dane w formularzu.`);
				scroll(0, 0);
			}
			if (e.response?.status === 401) {
				auth.signOut();
			}
		}
	};

	const deleteFile = async (position: number, fileName: string) => {
		try {
			const response = await axios.delete('/update_file.php', {
				data: {
					id,
					fileName,
					position,
				},
			});
			if (response?.status === 204) {
				getTender({ onlyFiles: false });
			}
		} catch (e) {
			if (e.response?.status === 400) {
				setError(`Nie można usunąć pliku.`);
				scroll(0, 0);
			}
			if (e.response?.status === 401) {
				auth.signOut();
			}
		}
	};

	const setFilePosition = async (position: number, fileName: string) => {
		try {
			const response = await axios.put('/update_file.php', {
				id,
				fileName,
				position,
			});
			if (response?.status === 204) {
				getTender({ onlyFiles: false });
			}
		} catch (e) {
			if (e.response?.status === 400) {
				setError(`Nie można zmienić pozycji.`);
				scroll(0, 0);
			}
			if (e.response?.status === 401) {
				auth.signOut();
			}
		}
	};
	if (!auth.user || !id) {
		return null;
	}

	if (
		!/^[0-9]{3}_[0-9]{4}$/.test(id) &&
		!/^[0-9]{2}_[0-9]{2}_[0-9]{4}$/.test(id)
	) {
		return null;
	}

	return (
		<Layout	>
			<StyledArticle>
				<StyledH2 as="h1" color="info">
					{`Przetarg - edycja ${id.replace(/_/g, '/')}`}
				</StyledH2>
				<MessageDiv>
					{status ? (
						<StyledForm>
							{error ? <StyledError>{error}</StyledError> : <StyledGap />}
							<StyledLabel htmlFor="status">Status:</StyledLabel>
							<StyledSelect
								id="status"
								name="status"
								value={status}
								onChange={(e) => setStatus(e.currentTarget.value)}
							>
								{id[3] === '_' ? (
									<>
										<option value="przetarg archiwum">przetarg archiwum</option>
										<option value="przetarg trwający">przetarg trwający</option>
										<option value="przetarg zakończony">
											przetarg zakończony
										</option>
									</>
								) : (
									<>
										<option value="sprzedaż archiwum">sprzedaż archiwum</option>
										<option value="sprzedaż trwająca">sprzedaż trwająca</option>
										<option value="sprzedaż zakończona">
											sprzedaż zakończona
										</option>
									</>
								)}
							</StyledSelect>
							<br />
							<StyledLabel htmlFor="title">Tytuł:</StyledLabel>
							<StyledInput
								as="textarea"
								autoCapitalize="none"
								autoCorrect="off"
								id="title"
								type="text"
								name="title"
								spellCheck="false"
								value={title}
								style={{ resize: 'none' }}
								onChange={(e: any) => {
									setTitle(e.target.value);
								}}
							/>
							{/* <StyledLabel>Opis:</StyledLabel>
						<Editor data={description} setData={setDescription} />
						<p></p> */}
							<StyledLabel>Informacje:</StyledLabel>
							<Editor data={info} setData={setInfo} />

							<ButtonContainer>
								<StyledButton
									type="button"
									onClick={() => {
										navigate('/konto/przetargi/lista');
									}}
								>
									Anuluj
								</StyledButton>
								<StyledButton
									type="button"
									onClick={editTender}
									marginleft="16px"
								>
									Zapisz
								</StyledButton>
							</ButtonContainer>
							{files.length > 0 ? (
								<>
									<StyledLabel>
										Załączone pliki:{' '}
										{status === 'sprzedaż archiwum' ||
										status === 'przetarg archiwum'
											? '(w archiwum nie można dodawać i usuwać plików)'
											: null}
									</StyledLabel>
									<StyledTableContainer>
										<StyledFilesTable>
											<thead>
												<tr>
													<th>Kolejność</th>
													<th>Nazwa</th>
													<th>Data</th>
													<th>Usuwanie</th>
												</tr>
											</thead>
											<tbody>
												{files.map((file: any) => (
													<tr key={file.name}>
														<td className="position">
															{' '}
															<button
																onClick={() => {
																	if (file.position > 1) {
																		setFilePosition(
																			file.position - 1,
																			file.name
																		);
																	}
																}}
															>
																<strong>&#8613;</strong>
															</button>
															<span>{file.position}</span>
															<button
																onClick={() => {
																	if (file.position < files.length) {
																		setFilePosition(
																			file.position + 1,
																			file.name
																		);
																	}
																}}
															>
																<strong>&#8615;</strong>
															</button>
														</td>

														<td className="name">
															<a
																href={`https://api.mzk.bydgoszcz.pl/uploads/przetargi/${id}/${file.name}`}
																target="_blank" rel="noreferrer"
															>
																{file.name}
															</a>
														</td>
														<td className="date">{file.date}</td>
														<td className="delete">
															<button
																disabled={
																	status === 'sprzedaż archiwum' ||
																	status === 'przetarg archiwum'
																}
																onClick={() => {
																	const c = confirm(
																		'Czy chcesz usunąć ten plik ?'
																	);
																	if (c) {
																		deleteFile(file.position, file.name);
																	}
																}}
															>
																usuń
															</button>
														</td>
													</tr>
												))}
											</tbody>
										</StyledFilesTable>
									</StyledTableContainer>
								</>
							) : null}
							{status === 'sprzedaż archiwum' ||
							status === 'przetarg archiwum' ? null : (
								<Dropzone onDrop={uploadFiles}>
									{({ getRootProps, getInputProps }) => (
										<section className="container">
											<div {...getRootProps({ className: 'dropzone' })}>
												<input {...getInputProps()} />
												<p>
													Kliknij tutaj aby dodać pliki lub przeciągnij pliki w
													to miejsce.
												</p>
											</div>
										</section>
									)}
								</Dropzone>
							)}
						</StyledForm>
					) : null}
				</MessageDiv>
			</StyledArticle>
		</Layout>
	);
};

export default EdytujPage;
